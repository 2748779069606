body {
  background-color: #f1f2ff;
}
.App {
  font-family: "Roboto", sans-serif;
  text-align: center;
  letter-spacing: 0.3px;
}
label {
  display: block;
  margin-bottom: 0.5em;
}
h1 {
  color: #06060c;
  font-weight: 600;
  margin: 50px 0 -4px 0;
}
span {
  color: #393b4c;
  font-size: 10px;
  font-weight: 600;
  display: inherit;
  margin-top: -3px;
  padding-left: 116px;
  letter-spacing: 1.2px;
}
a:link, a:visited {
  color: #393b4c;
  transition: color 0.5s ease;
}
a:hover {
  color: #8383d6;
}
h2 {
  color: #393b4c;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 8px;
}
.tab-list {
  margin-top: 36px;
  padding-left: 0;
  width: 440px;
  font-size: 16px;
  display: inline-block;
}
.tab-list-item,
.tab-list-active {
  width: 47%;
  margin: 0 5px;
  padding: 12px 0;
  border-radius: 8px;
  position: relative;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
}
.tab-list-item {
  color: #a9aed7;
  background-color: #fff;
  list-style: none;
  box-shadow: 3px 3px 8px #e1e4fb;
  transition: color 0.5s ease;
  transition: background-color 0.5s ease;
}
.tab-list-active {
  color: #fff;
  background-color: #393b4c;
  box-shadow: 3px 3px 8px #e1e4fb;
}
.tab-list-item:hover {
  color: #393b4c;
  background-color: #fff;
}
.tab-list-active:hover {
  color: #fff;
  background-color: #393b4c;
}
.uploadImg {
  width: 60px;
  height: auto;
}
input[type="file"] {
  display: none;
}
textarea,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.uploadFile {
  width: 400px;
  padding: 12px;
  margin-bottom: 28px;
  border: .5px solid #9296b3;
  border-radius: 8px;
  color: #393b4c;
  background-color: #e1e4fb;
  box-shadow: 3px 3px 8px #e1e4fb;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  cursor: pointer;
  transition: color 0.5s ease;
  transition: background-color 0.5s ease;
}
.uploadFile:hover {
  color: #393b4c;
  background-color: #d0d4f6;
}
#metaTitle,
#metaDescription,
#metaKeyword,
#addMeta {
  border-radius: 8px;
  padding: 12px;
  margin: 2px 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  box-shadow: 3px 3px 8px #e1e4fb;
  transition: box-shadow 0.5s ease;
}
#metaTitle,
#metaDescription,
#metaKeyword {
  color: #393b4c;
  width: 400px;
  font-weight: 300;
  border: none;
  background-color: #fff !important;
}
#metaTitle::placeholder,
#metaDescription::placeholder,
#metaKeyword::placeholder {
  color: #9296b3;
}
#metaTitle:focus,
#metaDescription:focus,
#metaKeyword:focus {
  border: none;
  outline: none;
  box-shadow: 3px 3px 8px #d7dbff;
}
#metaTitle,
#metaDescription {
  height: 20px;
  overflow: auto;
  resize: none;
}
#metaKeyword {
  height: 100px;
  overflow: auto;
  resize: none;
}
#charLenght {
  width: 40px;
  color: #a9aed7;
  font-size: 8px;
  text-align: right;
  position: absolute;
  display: inline;
  margin-top: -8px;
  margin-left: -48px;
}
#addMeta {
  width: 200px;
  margin: 20px 0 50px 0;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.3px;
  border: none;
  background-color: #393b4c;
  cursor: pointer;
  transition: color 0.5s ease;
  transition: background-color 0.5s ease;
}
#addMeta:hover {
  color: #f1f2ff;
  background-color: #06060c;
}
.Footer {
  height: 16px;
  width: 100%;
  left: 0;
  bottom: 0;
  position: fixed;
  padding: 10px 0 16px 0;
  text-align: center;
  background-color: #9296b3;
}
#copyright {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: #393b4c;
}
.contributors {
  margin-left: 5px;
  position: relative;
  display: inline-block;
  background: none;
	border: none;
  padding: 0;
  font-size: 13px;
  text-decoration: underline;
	cursor: pointer;
  transition: color 0.5s ease;
}
.contributors:hover {
  color: #393b4c;
}
.popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  position: fixed;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 30%;
  right: 30%;
  top: 7%;
  bottom: 7%;
  padding-bottom: 16px;
  margin: auto;
  overflow: auto;
  border-radius: 20px;
  background: white;
  color: #393b4c;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.popup_inner::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}
.popup-header {
  margin-top: 32px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
}
.creator {
  font-weight: 400;
}
.creator a {
  text-decoration: none;
}
.contributor {
  font-weight: 300;
}
.close-btn {
  position: fixed;
  top: 7%;
  right: 30%;
  margin-top: 12px;
  margin-right: 12px;
  background: none;
	border: none;
  padding: 0;
}
.close-btn img {
  width: 36px;
  cursor: pointer;
  transition: filter 0.5s ease;
}
.close-btn img:hover {
  filter: saturate(6) brightness(1);
  cursor: pointer;
}

@media only screen and (min-height: 1200px) {
  .App {
    margin-top: 220px;
  }
  .popup_inner {
    top: 18%;
    bottom: 18%;
  }
  .close-btn {
    position: fixed;
    top: 18%;
  }
}

@media only screen and (min-height: 900px) {
  .App {
    margin-top: 100px;
  }
  span { padding-left: 120px; }
  .Footer {
    height: 24px;
    padding: 12px 0 30px 0;
  }
  .popup_inner {
    top: 16%;
    bottom: 16%;
    padding-bottom: 16px;
  }
  .close-btn {
    position: fixed;
    top: 16%;
  }
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 24px;
    margin: 32px 0 -4px 0;
  }
  span { padding-left: 74px; }
  h2 { font-size: 13px; }
  .tab-list {
    width: 100%;
    margin-top: 20px;
  }
  .uploadFile,
  #metaTitle,
  #metaDescription,
  #metaKeyword {
    width: 90%;
  }
  #addMeta {
    margin-top: 12px;
    margin-bottom: 40px;
  }
  .Footer {
    height: 14px;
    padding: 4px 0 10px 0;
  }
  #copyright,
  .contributors {
    font-size: 11px;
  }
  .uploadImg {
    width: 40px;
    height: auto;
  }
  .popup_inner {
    left: 2%;
    right: 2%;
    top: 2%;
    bottom: 2%;
    padding-bottom: 16px;
  }
  .close-btn {
    position: fixed;
    top: 2%;
    right: 2%;
    margin-top: 6px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 370px) {
  h1 {
    font-size: 18px;
    margin: 30px 0 -4px 0;
  }
  span { padding-left: 74px; }
  h2 { font-size: 12px; }
  .tab-list {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
  }
  .tab-list-item,
  .tab-list-active {
    width: 46%;
  }
  .uploadFile {
    margin-bottom: 20px;
  }
  .uploadFile,
  #metaTitle,
  #metaDescription,
  #metaKeyword {
    width: 90%;
    font-size: 14px;
  }
  #addMeta {
    margin-top: 4px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .Footer {
    height: 12px;
    padding: 0px 0 10px 0;
  }
  #copyright,
  .contributors {
    font-size: 10px;
  }
  .uploadImg {
    width: 24px;
    height: auto;
  }
  .popup_inner {
    left: 2%;
    right: 2%;
    top: 2%;
    bottom: 2%;
    padding-bottom: 16px;
  }
  .close-btn {
    position: fixed;
    top: 2%;
    right: 2%;
    margin-top: 6px;
    margin-right: 6px;
  }
}
